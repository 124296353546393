import React from "react"
import { useAuth } from "@auth/useAuth"
import Create from "./Create"
import All from "./All"

const Enterprises = () => {
  const { hasScope } = useAuth() || {}

  if (!hasScope("create:space-operators") && !hasScope("use:public-api"))
    return null
  return (
    <div>
      <Create />
      <All />
    </div>
  )
}

export default Enterprises
