import React, { useState } from "react"
import { useAuth } from "@auth/useAuth"
import { toast } from "react-toastify"

const CreateEnterprise = () => {
  const { makeRequest } = useAuth() || {}

  const [name, setName] = useState("")
  const [externalId, setEid] = useState("")
  const [stripeCustomerId, setSci] = useState("")
  const [stripePaymentMethodId, setSpi] = useState("")
  const [progress, setProgress] = useState(false)

  const add = async () => {
    if (!name || progress) return
    setProgress(true)
    await makeRequest("enterprises/add", {
      name,
      externalId,
      stripeCustomerId,
      stripePaymentMethodId,
    })
      .then(() => null)
      .catch((e) => {
        console.log(e)
        toast.warn("Failed to add the details. Please refresh and try again.")
      })
    setProgress(false)
  }

  return (
    <div className={"mb-5"}>
      <h4>Add Enterprise</h4>
      <div className="row">
        <Input
          label={"Name"}
          value={name}
          setValue={(value) => setName(value)}
        />
        <Input
          label={"External ID"}
          value={externalId}
          setValue={(value) => setEid(value)}
        />
        <Input
          label={"Stripe Customer ID"}
          value={stripeCustomerId}
          setValue={(value) => setSci(value)}
        />
        <Input
          label={"Stripe Payment Method ID"}
          value={stripePaymentMethodId}
          setValue={(value) => setSpi(value)}
        />
        <div className="col-md-12">
          <div className="form-group">
            <button
              onClick={add}
              className="btn btn-primary mt-3 d-flex align-items-center justify-content-center"
              disabled={!name || !externalId || !stripeCustomerId || progress}
            >
              {progress && (
                <span
                  className="spinner-border spinner-border-sm mr-2"
                  role="status"
                  aria-hidden="true"
                />
              )}
              {progress ? "Adding..." : "Add"}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateEnterprise

const Input = ({
  label = "",
  value,
  setValue = () => null,
  placeholder = "",
}) => {
  return (
    <div className="col-md-6">
      <div className="form-group">
        <label htmlFor="">{label}</label>
        <input
          type="text"
          className="form-control"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder={placeholder}
        />
      </div>
    </div>
  )
}
