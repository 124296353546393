import React from "react"
// import { useAuth } from "@auth/useAuth"

const AllEnterprises = () => {
  // const { makeRequest, isAuthenticated } = useAuth() || {}

  return (
    <div>
      <h4>All Enterprises</h4>
    </div>
  )
}

export default AllEnterprises
