import React, { useEffect, useState } from "react"
import SEO from "@components/seo"
import Layout from "@components/layout"
import Enterprises from "@components/Enterprises"
import { useAuth } from "@auth/useAuth"

const EnterprisesPage = () => {
  const { isAuthenticated } = useAuth() || {}

  const [pageReady, setPageReady] = useState(false)

  useEffect(() => {
    if (isAuthenticated) {
      setPageReady(true)
    }
  }, [isAuthenticated])

  return (
    <>
      <SEO title={"Manage Enterprises"} />
      <Layout title={"Manage Enterprises"}>
        {pageReady ? <Enterprises /> : <div />}
      </Layout>
    </>
  )
}
export default EnterprisesPage
